import React from 'react'
import PropTypes from 'prop-types'
import bem from 'bem'
import css from './Product.module.scss'
import Link from 'components/LinkDuplicate'
import loadable from '@loadable/component'
import Ruble from 'components/Ruble'
import ButtonAddToCart from 'components/ButtonAddToCart'
import Button from 'components/Button'
import Mark from 'components/Mark'
import memoize from 'utils/memoize'

const b = bem.product(css)

const Product = props => {
	const { urlExample, icon, title, description, price, priceOld, type, negative, className, stats, partnerId } = props
	let { urlPage } = props

	if (partnerId) {
		urlPage = `/ref/${partnerId}` + urlPage
	}

	// with memoize
	const Svg = loadable(() => import(`../../images/products/${icon}.svg`))

	const linkTitle = `Посмотреть подробнее базу «${title}»`

	return (
		<div className={b({ negative }, className)}>
			<Link className={b('link')} to={urlPage} target={partnerId ? '_blank' : '_self'} title={linkTitle}>
				<div className={b('icon')}>
					<Svg />
				</div>
				<div className={b('title')} dangerouslySetInnerHTML={{ __html: title }} />
				<div className={b('description-wrapper')}>
					<div className={b('description')} dangerouslySetInnerHTML={{ __html: description }} />
				</div>
				<div className={b('price')}>
					<Ruble>{price}</Ruble>
				</div>
				{priceOld && (
					<>
						<div className={b('price-old')}>
							<Ruble>{priceOld}</Ruble>
						</div>
						<Mark className={b('discount')} bold>
							&minus;{Math.ceil(100 - (price * 100) / priceOld)}%
						</Mark>
					</>
				)}
			</Link>
			{stats && (
				<table className={b('stats')}>
					<tbody>
						<tr>
							<td>
								<span>Количество</span>
							</td>
							<td>
								<span>
									<strong>{stats.rows.formatted}</strong>
								</span>
							</td>
						</tr>
						<tr>
							<td>
								<span>Email-адресов</span>
							</td>
							<td>
								<span>
									<strong>{stats.emails.formatted}</strong>
								</span>
							</td>
						</tr>
						<tr>
							<td>
								<span>Телефонов</span>
							</td>
							<td>
								<span>
									<strong>{stats.phones.formatted}</strong>
								</span>
							</td>
						</tr>
					</tbody>
				</table>
			)}
			<div className={b('controls')}>
				<div className={b('control', { type: 'download' })}>
					<Button
						className="strong"
						icon="excel"
						size="sm"
						theme="link"
						to={urlExample}
						isNoBorder
						full
						download
						isLinkNative
						title="Скачать пример базы"
						rel="nofollow noopener noreferrer"
					>
						Скачать
					</Button>
				</div>
				{!partnerId && (
					<div className={b('control', { type: 'cart' })}>
						<ButtonAddToCart
							name={type}
							addProps={{
								theme: 'primary',
								size: 'sm',
								full: true,
								children: 'В корзину',
								className: 'strong',
							}}
							successProps={{
								theme: 'success',
								size: 'sm',
								full: true,
								children: 'В корзине',
								className: 'strong',
							}}
						/>
					</div>
				)}
				{partnerId && (
					<div className={b('control', { type: 'cart' })}>
						<Button className="strong" size="sm" theme="primary" to={urlPage} isLink title={linkTitle} target="_blank">
							Подробнее
						</Button>
					</div>
				)}
			</div>
		</div>
	)
}

Product.propTypes = {
	urlPage: PropTypes.string.isRequired,
	urlExample: PropTypes.string.isRequired,
	icon: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	description: PropTypes.string,
	price: PropTypes.number.isRequired,
	priceOld: PropTypes.number,
	negative: PropTypes.bool,
	className: PropTypes.string,
	stats: PropTypes.shape({
		rows: PropTypes.shape({
			default: PropTypes.number.isRequired,
			formatted: PropTypes.string.isRequired,
		}).isRequired,
		emails: PropTypes.shape({
			default: PropTypes.number.isRequired,
			formatted: PropTypes.string.isRequired,
		}).isRequired,
		phones: PropTypes.shape({
			default: PropTypes.number.isRequired,
			formatted: PropTypes.string.isRequired,
		}).isRequired,
	}),

	// ID партнера, переданного через iframe
	partnerId: PropTypes.number,
}

export default memoize(Product)
