import React from 'react'
import loadable from '@loadable/component'
import './async.scss'

const ButtonScroll = loadable(() => import('./ButtonScroll'))

export default props => {
	const size = props.size || 'md'
	return (
		<div className={`button-scroll button-scroll__size-${size}`}>
			<ButtonScroll {...props} />
		</div>
	)
}
