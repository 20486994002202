/**
 * Замена русских букв английскими и наоборот согласно раскладке клавиатуры
 * @param text
 */
const rotateLanguage = text => {
	if (!text) return text

	const ru = `йцукенгшщзхъфывапролджэячсмитьбю`
	const ruUpper = ru.toUpperCase()

	const en = `qwertyuiop[]asdfghjkl;'zxcvbnm,.`
	const enUpper = en.toUpperCase()

	let res = []
	for (let i = 0; i < text.length; i++) {
		const letter = text[i]
		let index = -1

		if (index === -1) index = ru.indexOf(letter)
		if (index !== -1) {
			res.push(en[index])
			continue
		}

		if (index === -1) index = ruUpper.indexOf(letter)
		if (index !== -1) {
			res.push(enUpper[index])
			continue
		}

		if (index === -1) index = en.indexOf(letter)
		if (index !== -1) {
			res.push(ru[index])
			continue
		}

		if (index === -1) index = enUpper.indexOf(letter)
		if (index !== -1) {
			res.push(ruUpper[index])
			continue
		}

		res.push(letter)
	}

	res = res.join('')

	return res
}

export default rotateLanguage
