import React, { useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import bem from 'bem'
import css from './Calc.module.scss'
import Sector from 'components/Sector'
import { Grid, Row, Col } from 'react-flexbox-grid'
import { Form, Field } from 'react-final-form'
import AdapterRadio from 'components/Adapter/Radio'
import AdapterCheckbox from 'components/Adapter/Checkbox'
import AdapterSlider from 'components/Adapter/Slider'
import Increment from 'components/Increment'
import Ruble from 'components/Ruble'
import Button from 'components/Button'
import Secondary from 'components/Secondary'
import FormMessage from 'components/FormMessage'
import { numberFormat } from 'utils/numberFormat'
import useSiteMetadata from 'hooks/useSiteMetadata'
import SchemaProduct from 'components/Schema/Product'
import { getCookie } from 'utils/cookies'
import ContactForm from './components/ContactForm'

const b = bem.calc(css)

// Рендеринг поля
const formField = (options, formProps) => {
	const { field, showPrice, showPricePrefix, showPricePostfix } = options
	const { form, values } = formProps
	const value = values[field.name]
	let checked = false
	let component = null
	switch (field.type) {
		case 'checkbox':
			checked = value === true
			component = AdapterCheckbox
			break
		case 'radio':
			checked = value === field.value
			component = AdapterRadio
			break
		case 'slider':
			checked = false
			component = AdapterSlider
			break
		default:
	}

	let fieldParams = { ...field, component }
	switch (field.type) {
		case 'checkbox':
		case 'radio':
			fieldParams = {
				...fieldParams,
				children:
					// Если есть счетчик и флажок установлен
					values[options.count] && values[field.name] === true
						? `${field.children}<span class="text-nowrap">: <strong>${numberFormat(
								values[options.count]
						  )}</strong></span>`
						: field.children,
			}
			break
		case 'slider':
			fieldParams = {
				...fieldParams,
				form,
				values,
				noMargin: true,
			}
			break
		default:
	}

	return (
		<Fragment key={`${field.name}-${field.value}`}>
			<div className={b('field', { type: field.type })}>
				<Field {...fieldParams} />
				{['checkbox', 'radio'].indexOf(field.type) !== -1 && typeof showPrice !== 'undefined' && (
					<div className={b('field-price')}>
						<div className={b('field-price-value')}>
							{showPricePrefix}
							{numberFormat(showPrice)} <Ruble />
							{showPricePostfix}
						</div>
					</div>
				)}
			</div>
			{options.fields && checked && (
				<div className={b(`inside-${field.size || 'md'}`)}>
					{options.fields.map(options => formField(options, formProps))}
				</div>
			)}
		</Fragment>
	)
}

// Отфильтрованные результаты калькулятора
const getValues = (fields, values, rounding) => {
	let total = 0

	const filter = (item, values) => {
		const { field, fields, price, priceCalc } = item
		const name = field.name
		const value = values[name]

		const checked = {
			checkbox: () => value === true,
			radio: () => value === field.value,
			slider: () => true,
		}[field.type]()

		if (!checked) return

		let res = { [name]: value }

		fields &&
			fields.map(item => {
				res = { ...res, ...filter(item, values) }
				return null
			})

		if ((price || priceCalc) && typeof value !== 'undefined') {
			const _price = priceCalc ? priceCalc(value, total) : price
			total += typeof value === 'number' ? _price * value : _price
		}

		return res
	}

	let res = {}

	fields.map(item => {
		res = { ...res, ...filter(item, values) }
		return null
	})

	// Округляем до десятков
	total = Math.round(total / rounding) * rounding

	const { required = {}, name, phone, email, message, source } = values
	res = { ...res, ...required, total, name, phone, email, message, source }

	res.partnerAi = getCookie('partnerAi', 'number')
	res.referralAi = getCookie('referralAi', 'number')

	return res
}

const Calc = props => {
	const { initialValues, fields, button, notification, help, schema, rounding, inner, isFormToggle } = props
	const [isFormShowed, setIsFormShowed] = useState(!isFormToggle)
	const [isLoading, setIsLoading] = useState(false)
	const [isError, setIsError] = useState(false)
	const [isSuccess, setIsSuccess] = useState(false)
	const { siteApiUrl } = useSiteMetadata()

	return (
		<div className={b()}>
			{schema && <SchemaProduct {...schema} />}
			<Form
				onSubmit={async values => {
					setIsLoading(true)
					let response
					try {
						response = await fetch(`${siteApiUrl}/calc`, {
							method: 'POST',
							headers: {
								'Content-Type': 'application/json;charset=utf-8',
							},
							body: JSON.stringify(getValues(fields, values, rounding)),
						})
						setIsLoading(false)

						if (!response.ok) {
							setIsError(true)
							return
						}

						setIsSuccess(true)
						setTimeout(() => setIsSuccess(false), 6000)
						setIsError(false)
					} catch (e) {
						setIsLoading(false)
						setIsError(true)
					}
				}}
				initialValues={initialValues}
				render={formProps => {
					const { handleSubmit, values } = formProps
					const calc = getValues(fields, values, rounding)
					const buttonTitle = button[calc.type] || 'Отправить'
					const notificationTitle =
						notification[calc.type] || 'Ваша заявка отправлена. Пожалуйста, ожидайте ответа на email'
					const helpTitle = help[calc.type]

					const inners = {
						contactForm: props => <ContactForm {...props} />,
						// other components
					}
					let innerType = 'contactForm'
					let innerProps = {}
					const _inner = inner[calc.type]
					if (_inner) {
						innerType = _inner.type
						innerProps = _inner.props || {}
					}
					const Inner = inners[innerType](innerProps)

					return (
						<form onSubmit={handleSubmit}>
							<Sector>
								<Grid fluid>
									<Row>
										<Col sm={10} smOffset={1} md={6} mdOffset={0} lg={6}>
											<div className={b('services')}>
												<h2 className="mb-lg">Что хотите сделать?</h2>
												{fields.map(options => formField(options, formProps))}
												{/*<pre>{JSON.stringify(calc, 0, 2)}</pre>*/}
												{/*<pre>{JSON.stringify(values, 0, 2)}</pre>*/}
											</div>
										</Col>
										<Col sm={10} smOffset={1} md={5} mdOffset={1} lg={5} lgOffset={1}>
											<div className={b('total')}>
												<h2 className="mb">Стоимость</h2>
												<div className={b('price', 'mb')}>
													<Increment isFormat>{calc.total}</Increment>
													<Ruble />
												</div>

												{isFormShowed && (
													<>
														{Inner}
														{helpTitle && <Secondary className="mb">{helpTitle}</Secondary>}
														<p className="no-last">
															<Button
																type="submit"
																theme="primary"
																icon="check"
																loading={isLoading}
																disabled={isLoading || isSuccess}
															>
																{!isSuccess ? buttonTitle : 'Отправлено'}
															</Button>
														</p>
													</>
												)}

												{!isFormShowed && (
													<Button theme="primary" icon="check" onClick={() => setIsFormShowed(!isFormShowed)}>
														{buttonTitle}
													</Button>
												)}

												{isError && (
													<FormMessage error>
														При отправке формы произошла ошибка. Пожалуйста, попробуйте позже
													</FormMessage>
												)}
												{isSuccess && <FormMessage success>{notificationTitle}</FormMessage>}
											</div>
										</Col>
									</Row>
								</Grid>
							</Sector>
						</form>
					)
				}}
			/>
		</div>
	)
}

Calc.defaultProps = {
	rounding: 10,
	help: {},
	button: {},
	notification: {},
	inner: {},
}

Calc.propTypes = {
	// Параметры по умолчанию
	initialValues: PropTypes.object,

	// Нужно ли сразу показывать форму или по нажатию на кнопку
	isFormToggle: PropTypes.bool,

	// Тексты для кнопки
	button: PropTypes.object,

	// Тексты для подсказок
	help: PropTypes.object,

	// Тексты для сообщений после отправки формы
	notification: PropTypes.object,

	// Округление цены
	rounding: PropTypes.number,

	// Поля
	fields: PropTypes.arrayOf(
		PropTypes.shape({
			field: PropTypes.object.isRequired, // Поле
			price: PropTypes.number, // Цена
			showPrice: PropTypes.number, // Цена выводимая рядом с полем (для checkbox, radio)
			count: PropTypes.string, // Имя поля слайдара для вывода количества в заголовке поля

			// Дочерние поля
			fields: PropTypes.arrayOf(
				PropTypes.shape({
					field: PropTypes.object.isRequired, // Поле
					price: PropTypes.number, // Цена
				}).isRequired
			),
		})
	).isRequired,

	// Настройки полей формы
	inner: PropTypes.object,
}

export default Calc
