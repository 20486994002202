import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Button'
import StoreContext from 'contexts/store'
import useDeferredActive from 'hooks/useDeferredActive'
import ymReachGoal from 'utils/ymReachGoal'

const ButtonAddToCart = props => {
	const storeContext = useContext(StoreContext)
	const { name, addProps, successProps } = props
	const currentDatabase = storeContext.data.databases.find(v => v.type === name)
	const isAdded = currentDatabase && !currentDatabase.loading
	const isLoading = currentDatabase && currentDatabase.loading
	const isDisabled = useDeferredActive(storeContext.data.loading, 600)

	return (
		<>
			{!isAdded && (
				<Button
					icon={isLoading ? 'loading' : 'cartAdd'}
					theme={addProps.theme || 'primary'}
					onClick={() => {
						storeContext.addDatabase(name)
						ymReachGoal('click-add-to-cart')
					}}
					disabled={isDisabled}
					{...addProps}
				>
					{addProps.children || 'Добавить в корзину'}
				</Button>
			)}
			{isAdded && (
				<Button
					icon={isLoading ? 'loading' : 'cartSuccess'}
					theme={successProps.theme || 'success'}
					isLink
					to="/cart.html"
					{...successProps}
				>
					{successProps.children || 'Перейти в корзину'}
				</Button>
			)}
		</>
	)
}

ButtonAddToCart.defaultProps = {
	addProps: {},
	successProps: {},
}

ButtonAddToCart.propTypes = {
	name: PropTypes.string.isRequired,
	addProps: PropTypes.shape({
		theme: PropTypes.oneOf(['default', 'primary', 'danger', 'success', 'light', 'link']),
		size: PropTypes.oneOf(['xs', 'sm', 'lg']),
		full: PropTypes.bool,
		children: PropTypes.string,
	}),
	successProps: PropTypes.shape({
		theme: PropTypes.oneOf(['default', 'primary', 'danger', 'success', 'light', 'link']),
		size: PropTypes.oneOf(['xs', 'sm', 'lg']),
		full: PropTypes.bool,
		children: PropTypes.string,
	}),
}

export default ButtonAddToCart
