import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import AdapterText from 'components/Adapter/Text'
import AdapterTextArea from 'components/Adapter/TextArea'
import v from 'utils/validators'

const ContactForm = props => {
	const { fields } = props
	const { name, phone, email, message } = fields
	return (
		<>
			{name && (
				<Field
					component={AdapterText}
					name="name"
					placeholder="Ваше имя"
					prefix="user"
					validate={name.required && v.compose(v.required)}
				/>
			)}
			{phone && (
				<Field
					component={AdapterText}
					name="phone"
					type="tel"
					placeholder="Телефон"
					prefix="phone"
					mask="phone"
					validate={phone.required && v.compose(v.required)}
				/>
			)}
			{email && (
				<Field
					component={AdapterText}
					name="email"
					type="email"
					placeholder="Email"
					prefix="mail"
					validate={email.required && v.compose(v.required)}
				/>
			)}
			{message && (
				<Field
					component={AdapterTextArea}
					name="message"
					placeholder="Опишите ваш проект"
					minRows={2}
					maxRows={10}
					validate={message.required && v.compose(v.required)}
				/>
			)}
		</>
	)
}

ContactForm.defaultProps = {
	fields: {},
}

ContactForm.propTypes = {
	fields: PropTypes.shape({
		name: PropTypes.shape({
			required: PropTypes.bool,
		}),
		phone: PropTypes.shape({
			required: PropTypes.bool,
		}),
		email: PropTypes.shape({
			required: PropTypes.bool,
		}),
		message: PropTypes.shape({
			required: PropTypes.bool,
		}),
	}).isRequired,
}

export default ContactForm
