import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-flexbox-grid'
import FeedbackButton from 'components/FeedbackButton'
import DbList from 'components/DbList'
import Pagination from 'components/Pagination'
import AdapterText from 'components/Adapter/Text'
import { Field, Form } from 'react-final-form'
import rotateLanguage from 'utils/rotateLanguage'
import PropTypes from 'prop-types'

const Catalog = props => {
	const [search, setSearch] = useState('')
	const { pageContext, partnerId } = props
	let { dbs, part, number, isMore, slug, type, partsTotal } = pageContext

	// Использование задержки при поиске баз для оптимизированного ввода текста
	const [showedDbs, setShowedDbs] = useState(part)
	/* eslint-disable react-hooks/exhaustive-deps */
	useEffect(() => {
		let res = searchHandler(search)
		if (!res.length) res = searchHandler(rotateLanguage(search))

		const timeout = setTimeout(() => setShowedDbs(res), 150)

		return () => {
			clearTimeout(timeout)
		}
	}, [search])

	// Обработчик поиска баз
	const searchHandler = search => {
		if (!search) return part
		const searchPrepare = search.toLowerCase().trim()
		if (!searchPrepare) return part
		let filteredByTitlePrimary = []
		let filteredByTitleSecondary = []
		let filteredByDescription = []
		dbs.forEach(db => {
			const _db = {
				...db,
				_search: {
					title: db.title.toLowerCase().indexOf(searchPrepare),
					description: db.description.toLowerCase().indexOf(searchPrepare),
				},
			}
			if (_db._search.title === 0) {
				filteredByTitlePrimary.push(_db)
				return
			}
			if (_db._search.title > 0) {
				filteredByTitleSecondary.push(_db)
				return
			}
			if (_db._search.description !== -1) {
				filteredByDescription.push(_db)
			}
		})

		filteredByTitlePrimary.sort((a, b) => (a.title > b.title ? 1 : -1))
		filteredByTitleSecondary.sort((a, b) => (a._search.title > b._search.title ? 1 : -1))
		filteredByDescription.sort((a, b) => (a.title > b.title ? 1 : -1))

		let res = [].concat(filteredByTitlePrimary, filteredByTitleSecondary, filteredByDescription)
		res = res.slice(0, 24)
		res = res.map(db => {
			const re = new RegExp(searchPrepare, 'gi')
			return {
				...db,
				title: db.title.replace(re, '<mark>$&</mark>'),
				description: db.description.replace(re, '<mark>$&</mark>'),
			}
		})

		return res
	}

	return (
		<div id="list">
			<Row>
				<Col lgOffset={3} lg={6}>
					<div className="mb-lg">
						<Form
							onSubmit={() => {}}
							render={formProps => {
								const { handleSubmit } = formProps
								return (
									<form onSubmit={handleSubmit}>
										<Field
											component={AdapterText}
											name="name"
											placeholder="Найти базу..."
											prefix="loup"
											autoComplete="off"
											onChange={e => setSearch(e.target.value)}
										/>
									</form>
								)
							}}
						/>
					</div>
				</Col>
			</Row>
			{!!showedDbs.length && (
				<div className="mb-lg">
					<DbList dbs={showedDbs} partnerId={partnerId} />
				</div>
			)}
			{search && !showedDbs.length && !partnerId && (
				<div className="text-center mb-lg">
					<div className="mb">
						<p className="fs-h4">По вашему запросу баз не найдено</p>
						<p>Вы можете заказать создание индивидуальной базы</p>
					</div>
					<p>
						<FeedbackButton theme="primary">Заказать базу</FeedbackButton>
					</p>
				</div>
			)}
			{search && !showedDbs.length && partnerId && (
				<div className="text-center mb-lg">
					<div className="mb">
						<p className="fs-h4">По вашему запросу баз не найдено</p>
					</div>
				</div>
			)}
			{!search && (
				<div className="text-center">
					<Pagination type={type} slug={slug} number={number} isMore={isMore} total={partsTotal} hash="list" />
				</div>
			)}
		</div>
	)
}

Catalog.propTypes = {
	partnerId: PropTypes.number,
}

export default Catalog
