/**
 * Процент значения на интервале. Например: значение 50 на [40; 60] = 50%
 * @param value
 * @param min
 * @param max
 * @returns {number}
 */
export const getValuePercentFromInterval = (value, min, max) => {
	// Величина 1 процента в заданном интервале
	const percentValue = (max - min) / 100

	// Величина искомого интервала
	const intervalValue = value - min

	// Величина искомого интервала в процентах
	return intervalValue / percentValue
}

/**
 * Коэффициент по графику косинуса в промежутке [Pi/2; Pi/2 + Pi/6]
 * @param v - текущее значение
 * @param min - минимальное значение
 * @param max - максимальное значение
 * @returns {number}
 */
export const coefficientCosDecreasing = (v, min, max) => {
	// Процент текущего значения от максимального
	// const percent = v / max * 100
	const percent = getValuePercentFromInterval(v, min, max)
	// console.log(percent, getValuePercentFromInterval(v, 50000, max));

	// Значение на оси X
	// Прибавляем Pi/2 чтобы получить график от Pi/2 до Pi
	// Берем третью часть от Pi/2 до Pi и ищем значение в этой точке.
	const val = Math.PI / 2 + ((Math.PI / 2 / 3) * percent) / 100

	// Прибавляем единицу, чтобы получить положительные значения
	return Math.cos(val) + 1
}

/**
 * Коэффициент по графику косинуса в промежутке [Pi/2; Pi/2 + Pi/6]
 * @param v - текущее значение
 * @param min - минимальное значение
 * @param max - максимальное значение
 * @returns {number}
 */
export const coefficientCosIncreasing = (v, min, max) => {
	// Процент текущего значения от максимального
	// const percent = v / max * 100
	const percent = getValuePercentFromInterval(v, min, max)
	// console.log(percent, getValuePercentFromInterval(v, 50000, max));

	// Значение на оси X
	// Прибавляем Pi/2 чтобы получить график от Pi/2 до Pi
	// Берем третью часть от Pi/2 до Pi и ищем значение в этой точке.
	const val = Math.PI + ((Math.PI / 2) * percent) / 100

	// Прибавляем единицу, чтобы получить положительные значения
	return Math.cos(val) + 1
}
